<template>
<v-container fluid>
  <v-row row>
    <v-col cols="12" >
      <v-card flat>

        <v-subheader>Super-admin functions: I HOPE YOU KNOW WHAT YOU ARE DOING</v-subheader>

        <v-col cols="12">
                <div slot="header"><h3>Setup admin credentials</h3></div>
                <v-card>
                    <v-card-text>Runs the admin setup routine, change the code in cloud functions</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="setupAdmin()" :loading="loading" outlined color="primary">Run</v-btn>
                    </v-card-actions>
                </v-card>
        </v-col>

        <v-col cols="12">

                <div slot="header"><h3>Generate Packaged Sales Report</h3></div>
                <v-card>
                    <v-card-text>Creates a sales report for packaged sales in botls and in square POS</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="true" @click="generateSalesReport" :loading="loading" outlined color="primary">Generate Report</v-btn>
                    </v-card-actions>
                </v-card>

        </v-col>

        <v-col cols="12">
                <div slot="header"><h3>Run super admin function</h3></div>
                <v-card>
                    <v-card-text></v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="superAdminFunction" :loading="loading"  outlined color="primary">Super Admin Function</v-btn>
                    </v-card-actions>
                </v-card>
        </v-col>

        <v-col cols="12">
        <div slot="header"><h3>Correct Member Numbers</h3></div>
        <v-card>
            <v-card-text>This process will go through all the member numbers, and assign the lowest member number available to anyone who has an empty number</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="correctMemberNumbers" :loading="loading"  outlined color="primary">Dispatch dev function</v-btn>
            </v-card-actions>
        </v-card>
        </v-col>

      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>


<script>
import { mapGetters, mapMutations} from 'vuex'
import axios from 'axios'
import api from '@/store/api'
export default {
    data() {
        return {
            airtable: false,
            welcome: false,
            active: null,
            sentToAdmins: false,
            allowWelcomeEmail: false,
        }
    },
    computed: {
        ...mapGetters ([
            'loading'
        ]),
    },
    methods: {
        ...mapMutations ([
            'setLoading'
        ]),
        sendWelcomeEmail () {
            this.setLoading(true)
            let self = this
            let uid = this.$store.state.user.uid
            const functionServer = ENV.functionsServer()
            return new Promise( function(resolve) {
                axios.post(functionServer + "sendwelcomeemail", {uid: uid})
                .then( function(response) {
                    self.setLoading(false)
                    resolve(response);
                });
            });  
        },
        async setupAdmin () {
            this.setLoading(true)
            let uid = this.$store.state.user.uid
            var functionsCall = global.FIREBASE.functions.httpsCallable('setAdminClaim')
            let response = await functionsCall(
                {
                    uid: uid, 
                }
            )
            this.setLoading(false)          
        },
        generateSalesReport () {
            let self = this
            this.setLoading(true)
            let uid = this.$store.state.user.uid
            const functionServer = ENV.functionsServer()
            return new Promise( function(resolve) {
                axios.post(functionServer + "generatesalesreport", {uid: uid})
                .then( function(response) {
                    self.setLoading(false)
                    resolve(response);
                 //   self.$router.push({path: 'available'}) //redirect to available now to see any new products
                });
            });  
        },
        async findMembershipOrdersWithIssue(){
            let query = global.FIREBASE.db.collection("orders").where("name", "==", "Membership")
        },
        superAdminFunction () {
            let self = this
            self.setLoading(true)
            api.getOrderDashboard(0)
             .then( () => {
                self.setLoading(false)
                return
            })
        },
        updateSquareOrderCompletions () {
            this.setLoading(true)
            let uid = this.$store.state.user.uid
            var functionsCall = global.FIREBASE.functions.httpsCallable('updateSquareOrderCompletions')
            functionsCall({uid: uid,})
            .then( (response) => {
                this.setLoading(false)
                return 
            })
        },
        correctMemberNumbers () {
            this.setLoading(true)
            let uid = this.$store.state.user.uid
            var functionsCall = global.FIREBASE.functions.httpsCallable('correctmembernumbers')
            functionsCall({uid: uid,})
            .then( (response) => {
                this.setLoading(false)
                return 
            })
        },

    },
}
</script>